 export enum SessionStorage {
     SHOW_ARCHIVED = "showArchived",
     KC_TOKEN = "kcToken",
     KC_REFRESH_TOKEN = "kcRefreshToken",
     BRUGERKODE = "brugerkode",
     ROLE = "role",
     SUPPORTSAG = "supportsag"
 }

 export enum LocalStorage{
    BRUGERKODE = "brugerkode"
 }